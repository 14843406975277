import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  decryptData,
  encryptData,
  getClientId,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const appAccess: any = createAsyncThunk(
  'dashboard/appAccess',
  async (apiParams: { data: object; token?: string; clientId?: string }) => {
    const options = {
      headers: {
        Authorization: `Bearer ${apiParams.token}`,
        'Content-type': 'application/json',
        clientid: getClientId(apiParams.clientId),
      },
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.APP_STORE_API}/User/app-access`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return {
      app_access: data.appAccess,
      app_url: data.appUrl,
    };
  },
);

import { notification, Progress } from 'antd';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '../../foundation/assets/images/logo_black_medium.png';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import {
  selectClientId,
  selectClientIp,
  selectUser,
} from '../authentication/redux/selectors';
import { AppStoreType } from '../authentication/redux/types';
import { appAccess } from './redux/async_thunks';

const Dashboard = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const client_ip = useSelector(selectClientIp);
  const clientId = useSelector(selectClientId);

  const handleAppClick = (app: AppStoreType) => async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const data = await dispatch(
        appAccess({
          token: user?.app_store_token?.token,
          data: {
            userId: user?.user_id,
            sessionId: user?.session_id,
            appId: app?.appId,
            clientIp: client_ip?.ipv4,
          },
          clientId: clientId,
        }),
        // @ts-ignore
      ).unwrap();

      setIsLoading(false);
      // Open the App
      window.open(`${data.app_url}?appAccess=${data.app_access}`, '_blank');
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: 'Error',
        description: `You are not authorized to access this App. Please contact the administrator.`,
      });
    }
  };

  const apps = useMemo(() => {
    const items = [];
    if (user?.app_store_apps) {
      for (const app of user.app_store_apps) {
        const percent_completion = app.appReleaseStage.key
          ? app.appReleaseStage.key.toString().replace(/[\d]+%\s?/g, '')
          : '0';

        const project_progress = parseInt(percent_completion);

        const progressProps: any = {
          type: 'circle',
          percent: project_progress,
          strokeColor: `#00b2a3`,
          strokeWidth: 7,
        };

        if (project_progress === 0 || project_progress === 100) {
          progressProps.format = () => (
            <>
              <img src={`${app.appPicture}`} />
            </>
          );
        }

        items.push(
          <li
            className={`l-app-store__app ${
              project_progress > 0
                ? 'l-app-store__app--enable'
                : 'l-app-store__app--disable'
            }`}
            key={app.appId}
            onClick={
              project_progress > 0
                ? handleAppClick(app)
                : (e) => e.stopPropagation()
            }
          >
            <Progress {...progressProps} />
            <div className="l-app-store__app-name">{app.appName}</div>
          </li>,
        );
      }
    }
    return items;
  }, [user]);

  return (
    <div className="l-app-store">
      {isLoading && <FullPageLoader message={`Checking your access`} />}
      <ul className="circle-container">{apps}</ul>
      <span className="circle-container-1" />
      <span className="circle-container-2" />
      <div className="l-app-store__centered-title">
        <div>
          <img src={Logo} alt="Dashdot Application Store" />
        </div>
        <div
          style={{
            marginTop: '5px',
            fontSize: '19px',
            color: 'rgba(0,0,0,.65)',
          }}
        >
          <strong>Client Centric</strong>
        </div>
      </div>
      <div className="l-app-store__bg"></div>
    </div>
  );
};

export default Dashboard;

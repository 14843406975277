import './_mobile_menu.style.scss';

import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import React, { useEffect } from 'react';

type Props = {
  visible: boolean;
  title: any;
  onClose: (visible: any) => any;
  children: React.ReactNode;
};

const MobileMenu: React.FC<any> = ({
  visible,
  onClose,
  title,
  children,
}: Props) => {
  useEffect(() => {}, [visible]);

  return (
    <div className="mobile-menu">
      <MenuOutlined
        onClick={() => onClose(!visible)}
        className="mobile-menu__icon"
      />
      <Drawer
        title={title}
        placement="right"
        onClose={() => onClose(false)}
        visible={visible}
        closeIcon={<CloseCircleOutlined />}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default MobileMenu;

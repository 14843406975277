import { createSlice } from '@reduxjs/toolkit';

import { fetchSessions } from './async_thunks';
import { SessionState } from './types';

const initialState: SessionState = {
  list: undefined,
  total_sessions: undefined,
};

export const nftSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSessions.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.total_sessions = action.payload.total_sessions;
    });
  },
});

export default nftSlice.reducer;

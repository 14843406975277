import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  decryptData,
  encryptData,
  getClientId,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchDashdotUsers: any = createAsyncThunk(
  'app_access/get',
  async (apiParams: { data: any; token: string; clientId?: string }) => {
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.APP_STORE_API}/Admin/view-profiles`,
      encryptedData,
      {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
      },
    );
    const data = decryptData(response.data);
    return { list: data.userProfile, roles: data.roles, apps: data.apps };
  },
);

export const updateAccess: any = createAsyncThunk(
  'app_access/update',
  async (apiParams: {
    data: object;
    token: string;
    clientId: string | undefined;
  }) => {
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.put(
      `${env_constants.APP_STORE_API}/Admin/edit-profile`,
      encryptedData,
      {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
      },
    );
    const data = decryptData(response.data);
    return data;
  },
);

export const deleteProfile: any = createAsyncThunk(
  'app_access/delete',
  async (apiParams: { data: object; token: string; clientId?: string }) => {
    const encryptedData: any = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.APP_STORE_API}/Admin/delete-profile`,
      {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);
    return data;
  },
);

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Typescript Props
type Props = {
  children: any;
};

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const location = useLocation();

  if (!sessionStorage.getItem('user')) {
    return (
      <Navigate
        replace
        to="/"
        state={{
          from: location,
        }}
      />
    );
  } else {
    return children;
  }
};

export default PrivateRoute;

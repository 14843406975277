import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification, TablePaginationConfig } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectClientId, selectUser } from '../authentication/redux/selectors';
import { endSession, fetchSessions } from './redux/async_thunks';
import SessionsTable from './sessions_table/SessionsTable';

const Sessions = () => {
  const [isLoading, setIsLoading] = useState(false);

  // Hook to manage table pagination.
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    showSizeChanger: false,
    size: 'small',
  });

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const clientId = useSelector(selectClientId);

  /**
   * Fetches the sessions records
   */
  const fetchData = async (page = 1) => {
    try {
      if (user?.app_store_token?.token) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          sessionId: user?.session_id,
          pageIndex: page,
        };
        const responseData = await dispatch(
          fetchSessions({
            data,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();

        setPagination((values) => ({
          ...values,
          total: responseData.total_sessions,
        }));
      }

      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Something went wrong, please try again later.`,
      });
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEndSession = useCallback(async () => {
    try {
      if (user?.app_store_token?.token) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          sessionId: user?.session_id,
        };

        await dispatch(
          endSession({
            data,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();

        await fetchData(1);

        notification.success({
          message: 'Success!',
          description: `Successfully updated the record.`,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Something went wrong, please try again later.`,
      });
      console.log(error);
      setIsLoading(false);
    }
  }, [user]);

  const confirm = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to end your sessions?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleEndSession,
      centered: true,
    });
  };

  return (
    <div className="sessions">
      <div className="sessions__header">
        <div>Active Sessions</div>
        <div>
          <Button type="primary" danger onClick={confirm}>
            End Sessions
          </Button>
        </div>
      </div>
      <SessionsTable
        isLoading={isLoading}
        fetchData={fetchData}
        pagination={pagination}
      />
    </div>
  );
};

export default Sessions;

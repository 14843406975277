import './styles/antd.style.less';
import './styles/styles.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Startup from './features/startup/Startup';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  // @ts-ignore
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Startup />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();

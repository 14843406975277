import { createSlice } from '@reduxjs/toolkit';

import { fetchDashdotUsers } from './async_thunks';
import { AppAccessState } from './types';

const initialState: AppAccessState = {
  profiles: undefined,
  roles: undefined,
  apps: undefined,
};

export const nftSlice = createSlice({
  name: 'app_access',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDashdotUsers.fulfilled, (state, action) => {
      state.profiles = action.payload.list;
      state.roles = action.payload.roles;
      state.apps = action.payload.apps;
    });
  },
});

export default nftSlice.reducer;

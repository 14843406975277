import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  decryptData,
  encryptData,
  getClientId,
  getSessionClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const login: any = createAsyncThunk(
  'auth/login',
  async (apiParams: { data: any }) => {
    let result: any;

    const encryptedData = encryptData(apiParams.data);
    const clientId = encryptData(
      `${window.navigator.userAgent}/${apiParams.data.clientIp}`,
    );

    await axios
      .post(`${env_constants.APP_STORE_API}/Auth/login`, encryptedData, {
        headers: {
          clientid: clientId,
          'Content-type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);

        return Promise.reject(decrypted);
      });

    const userData = {
      app_store_token: result.appStoreToken,
      app_store_role_id: result.appStoreRoleId,
      app_store_app_id: result.appStoreAppId,
      user_id: result.userId,
      user_picture: result.userPicture,
      session_id: result.sessionId,
      app_store_apps: result.appStoreApps,
      user_first_name: result.userFirstName,
      user_last_name: result.userLastName,
      user_email: result.userEmail,
    };

    sessionStorage.setItem('user', JSON.stringify(userData));

    return {
      data: userData,
      status: result.responseCode,
    };
  },
);

export const refreshToken: any = createAsyncThunk(
  'auth/refreshToken',
  async (apiParams: { data: any }) => {
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.patch(
      `${env_constants.APP_STORE_API}/Auth/refresh-token`,
      encryptedData,
      {
        headers: {
          clientid: getSessionClientID(),
          'Content-type': 'application/json',
        },
      },
    );

    const data = decryptData(response.data);
    const currentDataUnparsed = sessionStorage.getItem('user');

    const currentData = JSON.parse(currentDataUnparsed || '');

    const userData = {
      ...currentData,
      app_store_token: data.appStoreToken,
      user_id: data.userId,
      session_id: data.sessionId,
    };

    sessionStorage.setItem('user', JSON.stringify(userData));

    return userData;
  },
);

export const fetchClientData: any = createAsyncThunk(
  'auth/clientData',
  async () => {
    let result: any;

    await axios
      .get(`${env_constants.APP_STORE_API}/Client/ip`, {
        headers: {
          'Content-type': 'application/json',
          clientid: encryptData(`${window.navigator.userAgent}`),
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);

        return Promise.reject(decrypted);
      });

    const clientData = { ipv4: result?.ip };

    sessionStorage.setItem('client_ip', JSON.stringify(clientData));

    return clientData;
  },
);

export const logout: any = createAsyncThunk(
  'auth/logout',
  async (apiParams: { data: any; token: string; clientId?: string }) => {
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.delete(
      `${env_constants.APP_STORE_API}/Auth/logout`,
      {
        data: encryptedData,
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
      },
    );

    const { data } = decryptData(response.data);
    sessionStorage.clear();

    return data;
  },
);

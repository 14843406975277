import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectOAuthInfo = (state: RootState) => state.auth.oauth_info;

export const selectUser = (state: RootState) => state.auth.user;

export const selectClientIp = (state: RootState) => state.auth.client_ip;

export const selectClientId = (state: RootState) => state.auth.client_id;

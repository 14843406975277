import { notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import routes from '../../foundation/config/routes';
import Login from '../authentication/login/Login';
import { refreshToken } from '../authentication/redux/async_thunks';
import { selectUser } from '../authentication/redux/selectors';
import { setClientData, setOAuthInfo } from '../authentication/redux/slice';
import AppLayout from '../layout/Layout';
import PrivateRoute from './private_route/PrivateRoute';
import PublicRoute from './public_route/PublicRoute';

function Startup() {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const errorHandler = () => {
    notification.error({
      message: 'Session Expired!',
      description: `Please login again.`,
    });
    setIsLoading(false);
    sessionStorage.clear();
    navigate('/');
  };

  const setAuthData = async () => {
    try {
      const client_data = sessionStorage.getItem('client_ip');
      const oauth_info = sessionStorage.getItem('oauth_info');

      const currentAuthDataUnparsed = await sessionStorage.getItem('user');
      if (client_data) {
        dispatch(setClientData(JSON.parse(client_data)));
      }

      if (currentAuthDataUnparsed) {
        if (oauth_info) {
          const parsedOAuthInfo = JSON.parse(oauth_info);
          dispatch(setOAuthInfo(parsedOAuthInfo));
        }

        const currentData = JSON.parse(currentAuthDataUnparsed);

        await dispatch(
          refreshToken({
            data: {
              userId: currentData.user_id,
              sessionId: currentData.session_id,
              appId: currentData.app_store_app_id,
            },
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  useEffect(() => {
    if (!user) {
      setAuthData();
    }
  }, []);

  const privateRoutes = useMemo(
    () =>
      routes.map((item) => {
        return (
          <Route
            key={item.path}
            path={item.path}
            element={
              <PrivateRoute>
                <item.component />
              </PrivateRoute>
            }
          />
        );
      }),
    [],
  );

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        {privateRoutes}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default Startup;

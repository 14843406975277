import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { encryptData } from '../../../foundation/utils/api';
import { fetchClientData, login, logout, refreshToken } from './async_thunks';
import { AuthState, ClientIp, OAuthUserInfo } from './types';

const initialState: AuthState = {
  oauth_info: undefined,
  user: undefined,
  client_ip: undefined,
  client_id: undefined,
};

export const nftSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOAuthInfo: (state, action: PayloadAction<OAuthUserInfo>) => {
      state.oauth_info = action.payload;
    },
    setClientData: (state, action: PayloadAction<ClientIp>) => {
      state.client_ip = action.payload;
    },
    setClientId: (state, action: PayloadAction<string>) => {
      state.client_id = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientData.fulfilled, (state, action) => {
        state.client_ip = action.payload;
      })
      .addCase(login.fulfilled, (state, action) => {
        // @ts-ignore
        state.user = action.payload.data;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.user = action.payload;
        state.client_id = encryptData(
          `${window.navigator.userAgent}/${state.client_ip?.ipv4}/${action.payload.session_id}`,
        );
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = undefined;
        state.oauth_info = undefined;
        state.client_ip = undefined;
        state.client_id = undefined;
      });
  },
});

export const { setOAuthInfo, setClientData, setClientId } = nftSlice.actions;

export default nftSlice.reducer;

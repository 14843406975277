import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Table, TableColumnsType, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useMedia from '../../../foundation/utils/useMedia';
import { selectApps, selectProfiles } from '../redux/selectors';

type Props = {
  isLoading: boolean;
  handleAccessClick: (record: any) => void;
  data: any[];
};
const ProfileTable = ({ isLoading, handleAccessClick, data }: Props) => {
  /**
   * Screen controls
   */
  const isMediumScreen = useMedia('(max-width: 768px)');
  const enableElipsis = useMedia('(min-width: 1275px)');

  /**
   * Selector to select Sessions list
   */
  const profiles = useSelector(selectProfiles);

  const apps = useSelector(selectApps);

  const { Title } = Typography;

  /**
   * Data table's columns.
   */
  const mdColumns: TableColumnsType<any> = useMemo(() => {
    const cols: TableColumnsType<any> = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: enableElipsis,
      },
    ];
    if (apps) {
      for (const app of apps) {
        const key = `app_${app.appId}`;
        cols.push({
          title: app.appName,
          dataIndex: key,
          key: key,
          ellipsis: enableElipsis,
        });
      }

      cols.push({
        title: 'Action',
        dataIndex: 'type',
        key: 'type',
        width: 80,
        fixed: 'right',
        render: (text: any, record: any) => {
          return (
            <EditOutlined
              className="app_access__table-edit-btn"
              onClick={onUpdateClick(record)}
            />
          );
        },
      });
    }
    return cols;
  }, [apps]);

  const renderColumnData = (dataCol: any) => {
    return (
      <Row gutter={[24, 24]}>
        {dataCol &&
          dataCol.map((item: any, index: any) => (
            <Col span={11} key={index}>
              <Title className="h-title-muted">{item.title}</Title>
              <div className="h-text--muted">{item.value}</div>
            </Col>
          ))}
      </Row>
    );
  };

  const smColumns: TableColumnsType<any> = useMemo(() => {
    const cols: TableColumnsType<any> = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (email: string, record: any) => {
          return (
            <Row gutter={[24, 24]}>
              <Col span={20}>
                <Title className="h-title-muted">Email</Title>
                <div className="h-text--muted">{email}</div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <EditOutlined
                  className="app_access__table-edit-btn"
                  onClick={onUpdateClick(record)}
                />
              </Col>
            </Row>
          );
        },
      },
      {
        title: 'Suburb Selection Dashboard',
        dataIndex: 'app_1',
        key: 'app_1',
        render: (app_1: string, record: any) => {
          return (
            <>
              {renderColumnData([
                { title: 'Suburb Selection Dashboard', value: app_1 },
                {
                  title: 'Portfolio Planning Platform',
                  value: record['app_2'],
                },
              ])}
            </>
          );
        },
      },
      {
        title: 'Portfolio Management Portal',
        dataIndex: 'app_3',
        key: 'app_3',
        render: (app_3: string, record: any) => {
          return (
            <>
              {renderColumnData([
                { title: 'Portfolio Management Portal', value: app_3 },
                {
                  title: 'Acquisition Management Portal',
                  value: record['app_4'],
                },
              ])}
            </>
          );
        },
      },
      {
        title: 'Property Selection Dashboard',
        dataIndex: 'app_5',
        render: (app_5: string) => {
          return (
            <>
              <Title className="h-title-muted">
                Property Selection Dashboard
              </Title>
              <div className="h-text--muted">{app_5}</div>
            </>
          );
        },
      },
    ];
    return cols;
  }, [apps]);

  const columns = isMediumScreen ? smColumns : mdColumns;

  const getAccessInfo = useCallback(
    (record) => {
      if (profiles) {
        for (const item of profiles) {
          if (item.emailAddress === record.email) {
            return item;
          }
        }
      }
      return undefined;
    },
    [profiles],
  );

  const onUpdateClick = (record: any) => () => {
    const data = getAccessInfo(record);
    handleAccessClick(data);
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.email}
      //   pagination={pagination}
      bordered
      //   onChange={handleTableChange}
      loading={isLoading}
      //   scroll={{ y: 400, x: true }}
      size="middle"
    />
  );
};

export default ProfileTable;

import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import app_access from '../features/app_access/redux/slice';
import auth from '../features/authentication/redux/slice';
import sessions from '../features/sessions/redux/slice';

export interface IRootState {
  router: RouterState;
}

// Root Reducer.
export default (history: History) => ({
  router: connectRouter(history),
  auth,
  sessions,
  app_access,
});

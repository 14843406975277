import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  decryptData,
  encryptData,
  getClientId,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchSessions: any = createAsyncThunk(
  'sessions/get',
  async (apiParams: { data: any; token: string; clientId?: string }) => {
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.APP_STORE_API}/User/sessions`,
      encryptedData,
      {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
      },
    );

    const data = decryptData(response.data);
    return { list: data.sessions, total_sessions: data.totalSessions };
  },
);

export const endSession: any = createAsyncThunk(
  'sessions/delete-session',
  async (apiParams: { data: any; token: string; clientId?: string }) => {
    await axios.delete(`${env_constants.APP_STORE_API}/User/end-sessions`, {
      headers: {
        Authorization: `Bearer ${apiParams.token}`,
        'Content-type': 'application/json',
        clientid: getClientId(apiParams.clientId),
      },
      data: encryptData(apiParams.data),
    });

    return true;
  },
);

import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <mask
        id="mask0"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.814 6.545H8.186v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0111.2 4.327L13.581 2a8.258 8.258 0 00-5.395-2C3.646 0 0 3.564 0 8s3.647 8 8.186 8C12.28 16 16 13.09 16 8c0-.473-.074-.982-.186-1.455z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0)">
        <path
          fill="#FBBC05"
          d="M-.744 12.727V3.273L5.58 8l-6.325 4.727z"
        ></path>
      </g>
      <mask
        id="mask1"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.814 6.545H8.186v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0111.2 4.327L13.581 2a8.258 8.258 0 00-5.395-2C3.646 0 0 3.564 0 8s3.647 8 8.186 8C12.28 16 16 13.09 16 8c0-.473-.074-.982-.186-1.455z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1)">
        <path
          fill="#EA4335"
          d="M-.744 3.273L5.58 8l2.605-2.218 8.93-1.418V-.727H-.744v4z"
        ></path>
      </g>
      <mask
        id="mask2"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.814 6.545H8.186v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0111.2 4.327L13.581 2a8.258 8.258 0 00-5.395-2C3.646 0 0 3.564 0 8s3.647 8 8.186 8C12.28 16 16 13.09 16 8c0-.473-.074-.982-.186-1.455z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2)">
        <path
          fill="#34A853"
          d="M-.744 12.727l11.163-8.363 2.94.363 3.757-5.454v17.454H-.744v-4z"
        ></path>
      </g>
      <mask
        id="mask3"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.814 6.545H8.186v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0111.2 4.327L13.581 2a8.258 8.258 0 00-5.395-2C3.646 0 0 3.564 0 8s3.647 8 8.186 8C12.28 16 16 13.09 16 8c0-.473-.074-.982-.186-1.455z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3)">
        <path
          fill="#4285F4"
          d="M17.116 16.727L5.582 8 4.093 6.91l13.023-3.637v13.454z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  classNames?: string;
  message?: string;
};

function FullPageLoader({ classNames, message }: Props): JSX.Element {
  return (
    <div className={`full-page-loader ${classNames || ''}`}>
      <div>
        <Spin />
      </div>
      <div>{message}</div>
    </div>
  );
}

// PropTypes
FullPageLoader.propTypes = {
  classNames: PropTypes.string,
};

export default FullPageLoader;

import {
  Col,
  Row,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useMedia from '../../../foundation/utils/useMedia';
import { selectSessions } from '../redux/selectors';
import { Session } from '../redux/types';

/**
 * Typescript types
 */
type Props = {
  isLoading: boolean;
  pagination: TablePaginationConfig;
  fetchData: (page: number) => void;
};

/**
 * Component
 */
const SessionsTable = ({ fetchData, isLoading, pagination }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1); // current page number

  /**
   * Selector to select Sessions list
   */
  const sessions = useSelector(selectSessions);
  const { Title } = Typography;
  /**
   * Data table's columns.
   */
  const isMediumScreen = useMedia('(max-width: 768px)');
  const enableElipsis = useMedia('(min-width: 1275px)');

  const mdColumns: TableColumnsType<Session> = [
    {
      title: 'Login Date',
      dataIndex: 'loginDate',
      key: 'loginDate',
      ellipsis: enableElipsis,
      width: '15%',
      render: (text: string) =>
        moment(new Date(text).toISOString()).format('YYYY-MM-DD hh:mm A'),
    },
    {
      title: 'App Name',
      dataIndex: 'appName',
      key: 'appName',
      ellipsis: enableElipsis,
      width: '20%',
    },
    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      ellipsis: enableElipsis,
      width: '12%',
    },
    {
      title: 'Session Id',
      dataIndex: 'sessionId',
      key: 'sessionId',
      ellipsis: enableElipsis,
      width: '18%',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: enableElipsis,
      width: '23%',
    },
    {
      title: 'Current Session',
      dataIndex: 'isCurrentSession',
      key: 'isCurrentSession',
      ellipsis: enableElipsis,
      render: (text: boolean) => `${text ? 'Yes' : 'No'}`,
      width: '12%',
    },
  ];

  const renderColumnData = (dataCol: any) => {
    return (
      <Row gutter={[24, 24]}>
        {dataCol &&
          dataCol.map((item: any, index: any) => (
            <Col span={11} key={index}>
              <Title className="h-title-muted">{item.title}</Title>
              <div className="h-text--muted">{item.value}</div>
            </Col>
          ))}
      </Row>
    );
  };

  const smColumns = [
    {
      title: 'Active sessions',
      dataIndex: 'appName',
      key: 'appName',
      ellipsis: true,
      render: (appName: string, session: any) => {
        return (
          <>
            {renderColumnData([
              { title: 'App name', value: appName },
              {
                title: 'Login Date',
                value: moment(new Date(session.loginDate).toISOString()).format(
                  'YYYY-MM-DD hh:mm A',
                ),
              },
            ])}
          </>
        );
      },
    },
    {
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      ellipsis: true,
      render: (ipAddress: string, session: any) => {
        return (
          <>
            {renderColumnData([
              { title: 'IP Address', value: ipAddress },
              {
                title: 'Session Id',
                value: session.sessionId,
              },
            ])}
          </>
        );
      },
    },
    {
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      render: (location: string, session: any) => {
        return (
          <>
            {renderColumnData([
              { title: 'Location', value: location },
              {
                title: 'Current Session',
                value: session.isCurrentSession ? 'Yes' : 'No',
              },
            ])}
          </>
        );
      },
    },
  ];

  const columns = isMediumScreen ? smColumns : mdColumns;

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  /**
   * Handles table change
   */
  const handleTableChange = (
    paginationChange: TablePaginationConfig,
    filters: Record<string, (React.Key | boolean)[] | null>,
    sorter: any,
  ) => {
    if (paginationChange.current || paginationChange.current === 0) {
      // change the current page no
      setCurrentPage(paginationChange.current);
    }
  };

  return (
    <Table
      dataSource={sessions}
      columns={columns}
      rowKey={(record) => record.loginDate}
      pagination={pagination}
      bordered
      onChange={handleTableChange}
      loading={isLoading}
    />
  );
};

export default SessionsTable;

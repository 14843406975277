import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, notification, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectClientId, selectUser } from '../authentication/redux/selectors';
import AccessModal from './access_modal/AccessModal';
import ProfileTable from './profile_table/ProfileTable';
import { fetchDashdotUsers } from './redux/async_thunks';
import { selectProfiles, selectRoles } from './redux/selectors';

const AppAccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  /**
   * For search email in table
   */
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const profiles = useSelector(selectProfiles);
  const roles = useSelector(selectRoles);
  const clientId = useSelector(selectClientId);
  /**
   * Fetches the sessions records
   */
  const fetchData = async (page = 1) => {
    try {
      if (user?.app_store_token?.token) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          sessionId: user?.session_id,
          profileEmailSearch: null,
        };

        await dispatch(
          fetchDashdotUsers({
            data,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Something went wrong, please try again later.`,
      });
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAccessClick = (record: any) => {
    setModalData(record);
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
    setIsCreate(false);
  };

  const handleProfileCreate = () => {
    setIsCreate(true);
    handleAccessClick({
      emailAddress: '',
      userAppRole: [],
    });
  };

  const onChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const roleNames = useMemo(() => {
    const items: any = {};
    if (roles) {
      for (const item of roles) {
        items[item.roleId] = item.roleName;
      }
    }
    return items;
  }, [roles]);

  const dataSource = useMemo(() => {
    const source = [];

    if (profiles) {
      for (const item of profiles) {
        const data: any = {
          email: item.emailAddress,
        };
        const itemRoles = item.userAppRole;
        for (const role of itemRoles) {
          data[`app_${role.appId}`] = roleNames[role.roleId];
        }
        source.push(data);
      }
    }
    return source;
  }, [profiles]);

  const filterBy = (data: any[], type: string, value: string) => {
    if (value) {
      return data.filter((item: any) =>
        item[type].toLowerCase().includes(value.toLowerCase()),
      );
    }
    return data;
  };

  return (
    <div className="app_access">
      {isModalVisible && (
        <AccessModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={isCreate ? `Create Access` : `Update Access`}
          isCreate={isCreate}
        />
      )}
      <div className="app_access__header">
        <div>Access Management</div>
        <Row justify="center" gutter={[24, 10]}>
          <Col>
            <Input
              placeholder="Search email"
              onChange={onChange}
              allowClear
              value={searchValue}
              suffix={<SearchOutlined />}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleProfileCreate}
              style={{ minHeight: '48px' }}
            >
              Create Access
            </Button>
          </Col>
        </Row>
      </div>
      <ProfileTable
        isLoading={isLoading}
        handleAccessClick={handleAccessClick}
        data={filterBy(dataSource, 'email', searchValue)}
      />
    </div>
  );
};

export default AppAccess;

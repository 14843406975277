import { BackTop, Layout } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import routes from '../../foundation/config/routes';
import useMedia from '../../foundation/utils/useMedia';
import { selectUser } from '../authentication/redux/selectors';
import ContentWrapper from './content_wrapper/ContentWrapper';
import Header from './header/Header';

const AppLayout = () => {
  const location = useLocation();
  const isDesktop = useMedia('min-width: 960px');
  const [authUser, setAuthUser] = useState<any>(undefined);

  const user = useSelector(selectUser);

  useEffect(() => {
    setAuthUser(user);
  }, [user]);

  const routeConfig = useMemo(() => {
    for (const route of routes) {
      if (route.path === location.pathname) {
        return route;
      } else if (route.routes) {
        for (const childRoute of route.routes) {
          if (childRoute.path === location.pathname) {
            return childRoute;
          }
        }
      }
    }
  }, [location.pathname]);

  if (!authUser) {
    return <Outlet />;
  }

  return (
    <>
      <Layout className="c-app-layout">
        <Header routeConfig={routeConfig}>Header</Header>
        <Layout>
          <ContentWrapper routeConfig={routeConfig}>
            <Outlet />
          </ContentWrapper>
        </Layout>
      </Layout>
      {!isDesktop && <BackTop />}
    </>
  );
};

export default AppLayout;

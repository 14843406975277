import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import validateEmail from '../../../foundation/utils/validate_email';
import {
  selectClientId,
  selectUser,
} from '../../authentication/redux/selectors';
import {
  deleteProfile,
  fetchDashdotUsers,
  updateAccess,
} from '../redux/async_thunks';
import { selectApps, selectRoles } from '../redux/selectors';
import { AppRole, Profile } from '../redux/types';

type CheckboxValueType = string | number | boolean;

type Props = {
  visible?: true | false;
  closeModal: () => any;
  modalData: any;
  // onSubmit: (v: any) => any;
  modalTitle: string;
  isCreate?: boolean;
};

const AccessModal = ({
  visible = true,
  closeModal,
  modalData,
  modalTitle,
  isCreate,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [data, setData] = useState<Profile>(modalData);

  const user = useSelector(selectUser);
  const clientId = useSelector(selectClientId);

  /**
   * Selector to select Apps
   */
  const apps = useSelector(selectApps);

  /**
   * Selector to select Roles
   */
  const roles = useSelector(selectRoles);

  const modalOpts = {
    title: modalTitle,
    visible,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
  };
  const isBtnDisabled = useMemo(() => {
    const isEmailValid = validateEmail(data.emailAddress.trim());
    if (
      data.emailAddress &&
      data.emailAddress.trim().length > 0 &&
      isEmailValid &&
      data.userAppRole.length > 0
    ) {
      return false;
    }
    return true;
  }, [data]);

  const handleFormSubmit = async () => {
    if (isLoading || isBtnDisabled) {
      return;
    }
    try {
      if (user) {
        setIsLoading(true);

        const requestData = {
          sessionId: user?.session_id,
          userId: user?.user_id,
          userProfile: data,
        };

        await dispatch(
          updateAccess({
            data: requestData,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();

        const fetchData = {
          userId: user?.user_id,
          sessionId: user?.session_id,
          profileEmailSearch: null,
        };
        await dispatch(
          fetchDashdotUsers({
            data: fetchData,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
      closeModal();

      notification.success({
        message: 'Success!',
        description: 'Record updated successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while updating the access.',
      });
      setIsLoading(false);
      closeModal();
    }
  };

  const handleDeleteProfile = useCallback(async () => {
    if (isLoading) {
      return;
    }

    try {
      if (user && data) {
        setIsLoading(true);

        const requestData = {
          sessionId: user?.session_id,
          userId: user?.user_id,
          emailAddress: data.emailAddress,
        };

        await dispatch(
          deleteProfile({
            data: requestData,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();

        const fetchData = {
          userId: user?.user_id,
          sessionId: user?.session_id,
          profileEmailSearch: null,
        };

        await dispatch(
          fetchDashdotUsers({
            data: fetchData,
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
      closeModal();

      notification.success({
        message: 'Success!',
        description: 'Profile deleted successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong with the request.',
      });
      setIsLoading(false);
      closeModal();
    }
  }, [data]);

  const confirmDelete = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${data.emailAddress}?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleDeleteProfile,
      centered: true,
    });
  };

  const getRoleValue = useCallback(
    (appId) => {
      for (const role of data.userAppRole) {
        if (role.appId === appId) {
          return role.roleId;
        }
      }
    },
    [data],
  );

  const updateRole = useCallback(
    (appId: number) => (e: RadioChangeEvent) => {
      const updatedRoles: any[] = [];

      for (const role of data.userAppRole) {
        if (role.appId === appId) {
          updatedRoles.push({
            roleId: e.target.value,
            appId: role.appId,
          });
        } else {
          updatedRoles.push({
            roleId: role.roleId,
            appId: role.appId,
          });
        }
      }

      setData((item) => ({
        ...item,
        userAppRole: updatedRoles,
      }));
    },
    [data],
  );

  const roleOptions = useCallback(
    (appId: number) => {
      const options: any = [];

      if (roles) {
        for (const role of roles) {
          options.push(
            <Radio value={role.roleId} key={role.roleId}>
              {role.roleName}
            </Radio>,
          );
        }
      }

      return (
        <Radio.Group onChange={updateRole(appId)} value={getRoleValue(appId)}>
          <Space direction="vertical">{options}</Space>
        </Radio.Group>
      );
    },
    [roles, data],
  );

  const accessOptions = useMemo(() => {
    const options = [];

    if (apps) {
      for (const app of apps) {
        options.push(
          <Col span={24} style={{ marginBottom: 15 }}>
            <Checkbox
              value={app.appId}
              style={{ fontWeight: 600, marginBottom: 5 }}
            >
              {app.appName}
            </Checkbox>
            <Row>
              {' '}
              <Col span={18} offset={2}>
                <div>{roleOptions(app.appId)}</div>
              </Col>
            </Row>
          </Col>,
        );
      }
    }

    return options;
  }, [data, apps]);

  const allowedApps = useMemo(() => {
    const value = [];

    for (const appRole of data.userAppRole) {
      value.push(appRole.appId);
    }

    return value;
  }, [data]);

  const appAccessCheckBoxHandler = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const updatedData: Profile = {
        emailAddress: data.emailAddress,
        userAppRole: [],
      };

      for (const value of checkedValues) {
        let found = false;
        let role: AppRole | undefined = undefined;
        for (const item of data.userAppRole) {
          if (value === item.appId) {
            role = {
              roleId: item.roleId,
              appId: item.appId,
            };
            found = true;
            break;
          }
        }

        if (found && role) {
          updatedData.userAppRole.push(role);
        } else {
          updatedData.userAppRole.push({
            roleId: 1,
            appId: parseInt(value.toString()),
          });
        }
      }

      setData(updatedData);
    },
    [data],
  );

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData((v) => ({
        ...v,
        emailAddress: e.target.value,
      }));
    },
    [data],
  );

  return (
    <Modal {...modalOpts} className="c-form-modal">
      {isLoading && <FullPageLoader />}

      <div className="c-form-field">
        <div className="c-form-field__label">Email:</div>
        <div className="c-form-field__label--info">
          only valid dashdot emails are allowed
        </div>
        <div className="c-form-field__wrapper">
          <Input
            disabled={!isCreate}
            value={data.emailAddress}
            onChange={onEmailChange}
          />
        </div>
      </div>

      <Checkbox.Group
        style={{ width: '100%' }}
        value={allowedApps}
        onChange={appAccessCheckBoxHandler}
      >
        <Row>{accessOptions}</Row>
      </Checkbox.Group>

      <div className="c-form__btn-wrapper">
        {!isCreate && (
          <Button type="primary" danger onClick={confirmDelete}>
            Delete
          </Button>
        )}
        <Button type="primary" onClick={handleFormSubmit}>
          {isCreate ? `Create` : `Update`}
        </Button>
      </div>
    </Modal>
  );
};

export default AccessModal;

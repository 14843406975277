import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import { RouteType } from '../../../foundation/config/routes';

const { Content } = Layout;

type Props = {
  children: ReactNode;
  routeConfig: RouteType | undefined;
};

const ContentWrapper = ({ children, routeConfig }: Props) => {
  return <Content className="c-app-layout__content">{children}</Content>;
};

export default ContentWrapper;
